import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

function PatientSummary({
  // Props
  report,
}) {
  return (
    <Grid container columnSpacing={1}>
      <Grid size={12} sx={{mb: 0.5}}>
        <Typography variant="subtitle2">Patient</Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Name
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="patient-name">
          {report.studyDetails?.patientName}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          ID
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="patient-id">
          {report.studyDetails?.patientId}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          DOB
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="patient-dob">
          {/* @TODO BN-3380: remove replaceAll when date is stored in ISO */}
          {report.studyDetails?.patientDob?.replaceAll("/", "-")}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Gender
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="patient-gender">
          {report.studyDetails?.patientGender}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Preferred Language
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText">{report.studyDetails?.patientLanguage}</Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Phone
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="patient-phone-number">
          {report.studyDetails?.patientPhoneNumber}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Address
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="patient-address">
          {report.studyDetails?.patientAddress}
        </Typography>
      </Grid>
    </Grid>
  );
}

PatientSummary.propTypes = {
  report: PropTypes.object.isRequired,
};

export default React.memo(PatientSummary);
