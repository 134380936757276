import React from "react";
import {useFormContext} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import DoDisturbOn from "@mui/icons-material/DoDisturbOn";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useReportsDispatch} from "../../../contexts/ReportsContext.jsx";
import useConvertToPDF from "../../hooks/useConvertToPDF.jsx";
import useJwt from "../../hooks/useJwt.jsx";
import useVersionCompare from "../../hooks/useVersionCompare.jsx";
import MdnCriteriaTooltip from "../../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";
import ReadingPhysicianCommentsInput from "../inputs/ReadingPhysicianCommentsInput.jsx";
import RejectConfirmation from "../RejectConfirmation.jsx";

function SubmittedReportActions({
  // Props
  report,
  study,
  eSignEnabled,
  disabled = false,
  isPdfReport,
  originalFileUrl,
  setError,
  handleClose,
  setSuccessMessage,
  setLoadingMessage,
  addCoverPageToPDF,
  setFileUrl,
  setLoadData,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {isInAnyRole, fullName} = useJwt();
  const dispatch = useReportsDispatch();
  const compareVersions = useVersionCompare();

  //---------------------------------------------------------------------------
  // Form submission
  //---------------------------------------------------------------------------
  const {handleSubmit} = useFormContext();
  const [submitting, setSubmitting] = React.useState(false);

  const setSigningMessage = React.useCallback(
    (currentPage, totalPages) => {
      setLoadingMessage(`Signing Report. Rendering page ${currentPage} of ${totalPages} as PDF`);
    },
    [setLoadingMessage]
  );
  const convertSignedReportToPDF = useConvertToPDF(setSigningMessage);

  const onSign = React.useCallback(
    async (data) => {
      setSubmitting(true);
      const signedAt = DateTime.now().toMillis();

      try {
        let fileBuffer;

        //---------------------------------------------------------------------------
        // If an Uploaded report or BitRhythm Report > v1.3.1, add a cover page
        //---------------------------------------------------------------------------
        if (isPdfReport) {
          const signedReportFile = await addCoverPageToPDF(
            originalFileUrl,
            study,
            signedAt,
            data.physicianComment
          );

          fileBuffer = await signedReportFile.arrayBuffer();
        }
        //---------------------------------------------------------------------------
        // If a raw generated report, convert the report to a PDF
        //---------------------------------------------------------------------------
        else {
          // Scroll to the top so that there are not any partially-visible loading circles
          document.querySelector('[data-cy="pdf-viewer"]').scrollTo(0, 0);

          // Update the technician name on the DOM so that it is included in the PDF
          dispatch({
            type: "updated",
            updatedElement: {
              ...report,
              physicianComment: data.physicianComment,
              physicianSignedBy: fullName,
              signedAt,
            },
          });

          fileBuffer = await convertSignedReportToPDF("report-sheet");

          setLoadingMessage("Uploading signed report");
        }

        //---------------------------------------------------------------------------
        // Upload the PDF
        //---------------------------------------------------------------------------
        const formData = new FormData();
        const file = new Blob([fileBuffer]);
        formData.append("file", file);

        formData.append("physicianComment", data.physicianComment);
        formData.append("signedAt", signedAt);

        const {data: updatedReport} = await axios({
          method: "post",
          url: `/generatedReports/sign/${report.id}`,
          data: formData,
        });

        // Set the success message before the dispatcher in case filters hide this report state
        setSuccessMessage("Successfully signed");

        dispatch({type: "updated", updatedElement: {...report, ...updatedReport}});

        setSubmitting(false);
        handleClose();

        // Reload after Signing a raw report to transition to PDF
        // Reload after Signing an uploaded report, to reload the modified PDF
        setLoadingMessage("Reloading report");
        setLoadData(true);
      } catch (err) {
        setError(err.message);
        setSubmitting(false);
      }
    },
    [
      addCoverPageToPDF,
      convertSignedReportToPDF,
      dispatch,
      fullName,
      handleClose,
      isPdfReport,
      originalFileUrl,
      report,
      setError,
      setLoadData,
      setLoadingMessage,
      setSuccessMessage,
      study,
    ]
  );

  //---------------------------------------------------------------------------
  // Rejecting
  //---------------------------------------------------------------------------
  const [openRejectConfirmation, setOpenRejectConfirmation] = React.useState(false);
  const handleClickReject = React.useCallback(() => setOpenRejectConfirmation(true), []);
  const handleCancelRejectConfirmation = React.useCallback(() => setOpenRejectConfirmation(false), []);
  const handleCloseRejectConfirmation = React.useCallback(() => {
    setOpenRejectConfirmation(false);

    const isClinician = isInAnyRole(["clinicalStaff", "physician"]);

    if (!isClinician && compareVersions(report.version, ">", "v1.3.1")) {
      // Then the report will from PDF to raw, so reload
      setLoadingMessage("Reloading report");
      setLoadData(true);
    } else if (!isClinician && report.reportType === "Uploaded") {
      // Then the report will go from PDF with cover page, to PDF without cover page, so revert PDF
      setFileUrl(originalFileUrl);
    }

    handleClose();
  }, [
    compareVersions,
    handleClose,
    isInAnyRole,
    originalFileUrl,
    report.reportType,
    report.version,
    setFileUrl,
    setLoadData,
    setLoadingMessage,
  ]);

  return (
    <>
      <Box>
        <MdnCriteriaTooltip criteria={study.studyDetails?.physicianNotes} met={!!report.meetsMdnCriteria} />

        {isInAnyRole(["physician"]) && eSignEnabled && (
          <ReadingPhysicianCommentsInput
            originalFileUrl={originalFileUrl}
            study={study}
            isPdfReport={isPdfReport}
            disabled={disabled || submitting}
            addCoverPageToPDF={addCoverPageToPDF}
            setFileUrl={setFileUrl}
          />
        )}
      </Box>

      <form onSubmit={handleSubmit(onSign)} noValidate>
        <Box>
          {isInAnyRole(["physician"]) && eSignEnabled && (
            <>
              <Typography variant="body2" align="right" sx={{mb: 2}} data-cy="consent-message">
                By clicking &quot;Agree and Sign&quot;, I consent to signing this document electronically.
              </Typography>
              <LoadingButton
                variant="contained"
                color="secondary"
                fullWidth
                loading={submitting}
                disabled={disabled || submitting}
                data-cy="sign-button"
                type="submit"
              >
                Agree and Sign
              </LoadingButton>
            </>
          )}

          <Button
            variant="outlined"
            color="tertiary"
            fullWidth
            startIcon={<DoDisturbOn />}
            disabled={disabled || submitting}
            data-cy="reject-button"
            sx={{mt: 2}}
            onClick={handleClickReject}
          >
            Reject
          </Button>
        </Box>
      </form>

      <RejectConfirmation
        open={openRejectConfirmation}
        handleCancel={handleCancelRejectConfirmation}
        handleClose={handleCloseRejectConfirmation}
        report={report}
        setError={setError}
        setSuccessMessage={setSuccessMessage}
      />
    </>
  );
}

SubmittedReportActions.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  eSignEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isPdfReport: PropTypes.bool.isRequired,
  originalFileUrl: PropTypes.string,
  setError: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setLoadingMessage: PropTypes.func.isRequired,
  addCoverPageToPDF: PropTypes.func.isRequired,
  setFileUrl: PropTypes.func.isRequired,
  setLoadData: PropTypes.func.isRequired,
};

export default SubmittedReportActions;
