import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ArrowBack from "@mui/icons-material/ArrowBack";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useReports, useReportsDispatch} from "../../contexts/ReportsContext.jsx";
import {useRespectFilters} from "../../contexts/RespectFiltersContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import AutoLogout from "../AutoLogout/AutoLogout.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import Page404 from "../Page404/index.jsx";
import ReportStatusChip from "../ReportsPage/ReportStatusChip.jsx";
import ReportViewer from "../ReportViewer/ReportViewer.jsx";

function GeneratedReportPage({
  // Props
  reportId,
  angularActions,
  changesWereSaved,
}) {
  const {refreshJwtThresholdSeconds} = useEnvironmentVariables();
  const [error, setError] = React.useState(null);
  const [infoMessage, setInfoMessage] = React.useState(null);

  //---------------------------------------------------------------------------
  // API data fetching
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const reports = useReports();
  const report = React.useMemo(() => (reports ? reports[0] : {}), [reports]);
  const dispatch = useReportsDispatch();

  // All report states should be viewable on this page, so ignore all local storage filters
  // that are enforced by the dispatcher
  const {respectFilters, setRespectFilters} = useRespectFilters();

  const getData = React.useCallback(async () => {
    try {
      const {data: generatedReportResponse} = await axios({
        method: "get",
        url: "/generatedReports",
        params: {id: reportId},
      });

      dispatch({type: "init", elements: generatedReportResponse});
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [dispatch, reportId]);

  // Load the data on first render only
  React.useEffect(() => {
    if (respectFilters) {
      setRespectFilters(false);
    } else if (loading) {
      getData();
    }
  }, [getData, loading, respectFilters, setRespectFilters]);

  //---------------------------------------------------------------------------
  // Page title and additional info
  //---------------------------------------------------------------------------
  const title = React.useMemo(
    () => `${report?.reportType} Report ${report?.studyId}-${report?.reportNumber}`,
    [report?.reportNumber, report?.reportType, report?.studyId]
  );
  const auditText = React.useMemo(() => {
    let action = "Generated";
    if (report?.reportType === "Uploaded") {
      action = "Uploaded";
    } else if (report?.lastModifiedByUser?.fullName) {
      action = "Edited";
    }

    const user = report?.lastModifiedByUser?.fullName || report?.generatedByUser?.fullName;

    return user ? `${action} by ${user}` : "";
  }, [report?.generatedByUser?.fullName, report?.lastModifiedByUser?.fullName, report?.reportType]);

  if (!report?.state && !loading) {
    return <Page404 />;
  }

  return (
    <>
      <Alert message={infoMessage} setMessage={setInfoMessage} level="info" variant="snackbar" />

      <AppBar
        position="static"
        sx={{
          color: (theme) => theme.palette.text.primary,
          backgroundColor: (theme) => theme.palette.common.white,
        }}
        elevation={0}
      >
        <Toolbar sx={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
          <IconButtonWithTooltip
            title="Back to Reports"
            otherProps={{href: "/reports"}}
            data-cy="back-button"
          >
            <ArrowBack />
          </IconButtonWithTooltip>

          {!loading && (
            <>
              <Typography variant="h6" mx={1}>
                {title}
              </Typography>
              <ReportStatusChip status={report.state} width="auto" data-cy="report-status" />
              {auditText && (
                <Typography variant="caption" ml={1} data-cy="audit-message">
                  {auditText}
                </Typography>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <Alert message={error} setMessage={setError} level="error" />

      {loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: !error ? "calc(100vh - 64px)" : "calc(100vh - 64px - 48px)",
          }}
        >
          <TableLoading margin={false} />
          <Typography align="center" mt={1}>
            Loading report
          </Typography>
        </Box>
      )}

      {!loading && (
        <ReportViewer
          height={!error ? "calc(100vh - 64px)" : "calc(100vh - 64px - 48px)"}
          report={report}
          setInfoMessage={setInfoMessage}
          setError={setError}
          angularActions={angularActions}
          changesWereSaved={changesWereSaved}
        />
      )}

      {/* Set a 60 second refresh interval for the JWT with a 2 minute warning before being logged out. */}
      <AutoLogout warningSeconds={60 * 2} throttle={refreshJwtThresholdSeconds * 1000} />
    </>
  );
}

GeneratedReportPage.propTypes = {
  reportId: PropTypes.string.isRequired,
  angularActions: PropTypes.object.isRequired,
  changesWereSaved: PropTypes.bool.isRequired,
};

export default GeneratedReportPage;
