import React from "react";
import {useFormContext} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";
import useStudyTypeNames from "../../hooks/useStudyTypeNames.jsx";

function StudySummary({
  // Props
  report,
  study,
}) {
  const displayedStudyTypes = useStudyTypeNames();
  const {watch} = useFormContext();

  const watchRecordedDuration = watch("recordedDuration");

  const displayedStartDate = React.useMemo(() => {
    const validStudyStartDate = DateTime.fromISO(study.studyStartDate).year >= 2010;
    if (!validStudyStartDate) {
      return "Unknown Timestamp";
    }

    return <DateAndTime datetime={study.studyStartDate} zone={study.timeZone} />;
  }, [study.studyStartDate, study.timeZone]);

  const displayedEndDate = React.useMemo(() => {
    const validStudyEndDate = DateTime.fromISO(study.studyEndDate).year >= 2010;
    if (!validStudyEndDate) {
      return "Unknown Timestamp";
    }

    return <DateAndTime datetime={study.studyEndDate} zone={study.timeZone} />;
  }, [study.studyEndDate, study.timeZone]);

  const displayedStudyDuration = React.useMemo(() => {
    const durationInHours =
      report.reportType === "Summary" ? Math.round(watchRecordedDuration * 24) : watchRecordedDuration;

    // Use the recorded duration input on screen, if present
    let durationToUse = durationInHours;

    if (!durationToUse) {
      // Use the recorded duration from the study, if present
      durationToUse = study.recordedDuration;
    }

    // Otherwise, use a calculated value from the start and end time
    if (!durationToUse) {
      durationToUse = DateTime.fromISO(study.studyEndDate)
        .diff(DateTime.fromISO(study.studyStartDate), "hours")
        .as("hours");
    }

    // As a last option, use the configured duration for the study
    if (!durationToUse && study.configuredDuration) {
      durationToUse = study.configuredDuration;
    }

    const recordingDuration = Math.ceil(durationToUse / 24);
    return `${recordingDuration} day${recordingDuration === 1 ? "" : "s"}`;
  }, [
    report.reportType,
    study.configuredDuration,
    study.recordedDuration,
    study.studyEndDate,
    study.studyStartDate,
    watchRecordedDuration,
  ]);

  return (
    <Grid container columnSpacing={1}>
      <Grid size={12} sx={{mb: 0.5}}>
        <Typography variant="subtitle2">Study Details</Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Device
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="device-serial">
          {report.tzSerial}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Study ID
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="study-id">
          {study.id}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Start Date
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="study-start-date">
          {displayedStartDate}
        </Typography>
      </Grid>

      {report.reportType === "Summary" && (
        <>
          <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
            <Typography align="right" variant="reportText">
              End Date
            </Typography>
          </Grid>
          <Grid size={7} sx={{py: 0.25}}>
            <Typography variant="reportText" data-cy="study-end-date">
              {displayedEndDate}
            </Typography>
          </Grid>
        </>
      )}

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Type
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="study-type">
          {displayedStudyTypes[study.studyType]}
        </Typography>
      </Grid>

      {report.reportType === "Summary" && (
        <>
          <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
            <Typography align="right" variant="reportText">
              Study Duration
            </Typography>
          </Grid>
          <Grid size={7} sx={{py: 0.25}}>
            <Typography variant="reportText" data-cy="study-duration">
              {displayedStudyDuration}
            </Typography>
          </Grid>
        </>
      )}

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Facility
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="facility-name">
          {report.facilityName}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Indication
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="study-indication">
          {study.studyIndication}
        </Typography>
      </Grid>
    </Grid>
  );
}

StudySummary.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
};

export default React.memo(StudySummary);
