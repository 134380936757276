import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import DoDisturbOff from "@mui/icons-material/DoDisturbOff";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useReportsDispatch} from "../../../contexts/ReportsContext.jsx";
import useJwt from "../../hooks/useJwt.jsx";
import useVersionCompare from "../../hooks/useVersionCompare.jsx";
import MdnCriteriaTooltip from "../../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";
import ReasonForRejection from "../inputs/ReasonForRejection.jsx";

function RejectedReportActions({
  // Props
  report,
  study,
  disabled = false,
  eSignEnabled,
  setSuccessMessage,
  handleClose,
  setError,
  setLoadingMessage,
  setLoadData,
  originalFileUrl,
  setFileUrl,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {isInAnyRole, fullName} = useJwt();
  const dispatch = useReportsDispatch();
  const compareVersions = useVersionCompare();

  //---------------------------------------------------------------------------
  // Confirming rejection and un-rejecting
  //---------------------------------------------------------------------------
  const [submitting, setSubmitting] = React.useState(false);

  const handleClickConfirmRejection = React.useCallback(async () => {
    setSubmitting(true);

    try {
      const {data: updatedReport} = await axios({
        method: "patch",
        url: `/generatedReports/${report.id}`,
        data: {state: "rejectedByTech"},
      });

      // Update the local displayed report
      dispatch({
        type: "updated",
        updatedElement: {...report, ...updatedReport, rejectedByUser: {fullName}},
      });
    } catch (err) {
      setError(err.message);
    }

    if (report.reportType !== "Uploaded" && compareVersions(report.version, ">", "v1.3.1")) {
      // If the report is going from PDF to raw, reload
      setLoadingMessage("Reloading report");
      setLoadData(true);
    } else if (report.reportType === "Uploaded") {
      // Then the report will go from PDF with cover page, to PDF without cover page, so revert PDF
      setFileUrl(originalFileUrl);
    }

    setSubmitting(false);
  }, [
    report,
    compareVersions,
    dispatch,
    fullName,
    setError,
    setLoadingMessage,
    setLoadData,
    setFileUrl,
    originalFileUrl,
  ]);

  const handleClickUnreject = React.useCallback(async () => {
    setSubmitting(true);
    const isClinician = isInAnyRole(["clinicalStaff", "physician"]);

    try {
      let propertiesToUpdate;

      // If clinic user, move report back to submitted/published and keep the technician signature
      if (isClinician) {
        propertiesToUpdate = {state: eSignEnabled ? "submitted" : "published"};
      }
      // Otherwise, move report back to pending QC and remove the technician signature, since it
      // will need to be re-submitted
      else {
        propertiesToUpdate = {state: "generated", technicianSignedBy: null, technicianSignedAt: null};
      }

      const {data: updatedReport} = await axios({
        method: "patch",
        url: `/generatedReports/${report.id}`,
        data: propertiesToUpdate,
      });

      // Set the success message before the dispatcher in case filters hide this report state
      setSuccessMessage("Successfully un-rejected");

      dispatch({type: "updated", updatedElement: {...report, ...updatedReport}});

      if (
        report.state === "rejectedByPhysician" &&
        report.reportType !== "Uploaded" &&
        ((!isClinician && compareVersions(report.version, ">", "v1.3.1")) ||
          (isClinician && compareVersions(report.version, "<=", "v1.3.1") && !eSignEnabled))
      ) {
        // Then the report is going from raw to PDF or PDF to raw, so reload
        setLoadingMessage("Reloading report");
        setLoadData(true);
      }

      handleClose();
    } catch (err) {
      setError(err.message);
    }

    setSubmitting(false);
  }, [
    isInAnyRole,
    report,
    setSuccessMessage,
    dispatch,
    compareVersions,
    handleClose,
    eSignEnabled,
    setLoadingMessage,
    setLoadData,
    setError,
  ]);

  return (
    <>
      <Box>
        <ReasonForRejection
          report={report}
          disabled={disabled || submitting}
          handleClickConfirmRejection={handleClickConfirmRejection}
        />

        <MdnCriteriaTooltip criteria={study.studyDetails?.physicianNotes} met={!!report.meetsMdnCriteria} />
      </Box>

      <Box>
        <LoadingButton
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<DoDisturbOff />}
          loading={submitting}
          disabled={disabled || submitting}
          data-cy="unreject-button"
          onClick={handleClickUnreject}
        >
          Unreject
        </LoadingButton>
      </Box>
    </>
  );
}

RejectedReportActions.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  eSignEnabled: PropTypes.bool.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setLoadingMessage: PropTypes.func.isRequired,
  setLoadData: PropTypes.func.isRequired,
  originalFileUrl: PropTypes.string,
  setFileUrl: PropTypes.func.isRequired,
};

export default RejectedReportActions;
